.QuizWidgetItem {
  display: flex;
  flex-direction: column;
  padding: 0;
  &-Title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     &-Image {
      margin-bottom: 4px;
      background-size: contain;
    }
    &-Text {
      font-size: 14px;
      font-weight: 400;
      line-height: 143%;
      width: 100%;
    }
  }
  &-Text {
    font-weight: 12;
    font-weight: normal;
  }
  a {
    margin-top: 16px;
    align-self: self-start;
  }
}