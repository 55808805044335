.Birthday {
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid var(--border-color);
  &-Inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    &:hover {
      background-color: var(--main-color);
      color: var(--invariant-text-color);
      .Birthday-Cake {
        color: inherit;
      }
    }
  }
  &-Date {
    margin-right: 12px;
    text-align: center;
    width: 5rem;
  }
  &-Cake {
    color: var(--main-color);
  }
  &-Day {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.1;
  }
  &-Info {
    margin-left: 12px;
    overflow: hidden;
  }
  &-Name {
    font-size: 16px;
    margin: 0;
    color: inherit;
  }
  &-Name,
  &-Job {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-User {
    display: flex;
    text-decoration: none;
    color: inherit;
    align-items: center;
  }
}
