@import '../../../utils/global.scss';

.NewsReplyView {
    &-UserName {
        font-weight: bold;
        margin-right: 4px;
    }

    &-ThankHeader {}

    &-DateTime {
        display: inline-block;

        &_block {
            font-size: 12px;
            line-height: 22px;
            color: #979797;
            display: block;
        }
    }
    
    &-MoreBtn {
        color: #000;
        cursor: pointer;
        font-weight: bold;
    }
}