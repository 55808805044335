@import '~react-big-calendar/lib/sass/styles';

.rbc-btn-group {
  max-width: 250px;
}

.rbc-toolbar-label {
  max-width: 250px;
}

.rbc-toolbar-label {
  max-width: 205px;
}

.rbc-overlay {
  display: none;
}

.rbc-toolbar-label {
  max-width: 205px;
  display: flex;
  justify-content: center;
}

.rbc-toolbar {
  justify-content: space-between;
}


.CalendarP {
  // border: 1px solid #d3d9e0;
  background-color: #fff;
  border-radius: 4px;
  padding: 24px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  // box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.3);
  position: relative;
  &-Main {
    margin: 0!important;
    position: relative;
  }
  .rbc-calendar {
    min-height: 660px;
  }
  &-Event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 0 2px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 3px solid;
    height: 100%;
  }
}

// Стили для ишака
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .rbc-btn-group {
  width: 250px;
}

.CalendarP {
  .rbc-calendar {
    height: 660px;
  }
}
.rbc-toolbar-label {
  width: 250px;
}

.rbc-toolbar-label {
  width: 205px;
}

.rbc-overlay {
  display: none;
}

.CustomToolbar-Title {
  width: 205px;
  height: 35px;
}

.rbc-toolbar-label {
  width: 205px;
  display: flex;
  justify-content: center;
}
  .rbc-toolbar {
    width: 737px;
  }
  .CalendarP-Title {
  width: 150px;
  margin: 0;
  height: 100%;
  .InputLayout {
    .InputLayout-WorkZone {
      .InputLayout-EditZone {
        display: flex;
        justify-content: center; 
        input {
          height: 22px;
        }
      }
    }
  }
}
}

